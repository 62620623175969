import React from 'react';
import './App.css';
import { IndexPage, AppsPage, LoginPage } from './pages';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { StoreProvider } from './stores';

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#c71a1a',
    },
    primary: {
      main: '#fff',
    },
  }
})

const App: React.FC = () => {
  return (
    <StoreProvider>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <IndexPage></IndexPage>
            <HashRouter>
              <Switch>
                <Route path="/login" component={LoginPage} />                
                <Route path="/" component={AppsPage} />
                <Redirect to="/login" />
              </Switch>
            </HashRouter>
        </React.Fragment>
      </ThemeProvider>    
    </StoreProvider>
  );
}

export default App;
