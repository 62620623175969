import React, { useState } from 'react';
import { Theme, Card, CardContent, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import useReactRouter from 'use-react-router';
import { useStore } from '../../stores';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'fixed',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            backgroundColor: '#EAEAEA',
            // border: '1px solid black',
        },
        container2: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: 600,
            // border: '1px solid black',
        },
        card: {            
        },     
        logo: {
            flex: '1 1 20%',
            paddingTop: 24,
            paddingLeft: 24,
            // border: '1px solid black',
        },
        logoImg: {
            padding: '0 0 0 0',
            margin: '0 0 0 0',
            maxHeight: 100,
            maxWidth: 200,
            // border: '1px solid black',
        },
        header: {
            marginTop: 16,
            paddingLeft: 24,
            paddingRight: 24,
            fontSize: '24px',
            // border: '1px solid green',            
        },
        div: {
            // border: '1px solid red',
        },
        form: {
            padding: '0 0 0 0',
            // border: '1px solid black',
        },
        inner: {
            width: '200', 
            display: 'flex',
            flexDirection: 'column',
            // border: '1px solid black',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 400,
        },
        error: {
            fontSize: '12px',
            color: 'red',
            marginLeft: 0
        },
        divButton: {
            padding: 8, 
            width: "100%",
            position: 'relative',
        },
        button: {
            width: 400,
        },        
        buttonProgress: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -20,
        }
    }),
);

const LoginPage: React.FC = () => {
    const store = useStore();
    const classes = useStyles();
    const [state,setState] = useState({errorText:"",processing:false,domain:"",login:"",pwd:"", switchOn:false});
    const { history } = useReactRouter();
    const logo = "/rest/v1/public/resources/logo";
    
    const submitHandler = (e:any) => {
        e.preventDefault();
        setState({...state, processing:true, errorText:""});
        if (store) {
            store.login(state, state.switchOn).then(function(value) {
                if (value) {
                    store.setAuthorized(true);
                    history.push("/");
                } 
                else
                    setState({...state, processing:false, errorText:store.errorText});                
            });
        }
    };

    const toggleChecked = () => {
        const changeSwitch = !state.switchOn;
        setState({...state, switchOn: changeSwitch});
    };

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.container2}>
                    <Card className={classes.card}>
                        <div className={classes.logo}><img src={logo} alt="l" className={classes.logoImg}/></div>
                        <div className={classes.header}>Авторизация</div>
                        <div className={classes.div}>
                        <form className={classes.form} onSubmit={submitHandler}>
                            <CardContent className={classes.inner} >   
                                <TextField
                                    required
                                    id="domain"
                                    label="Домен"
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={e => setState({...state, domain:e.target.value, errorText:""})}
                                />
                                <TextField
                                    required
                                    id="login"
                                    label="Логин"
                                    className={classes.textField}
                                    margin="normal"
                                    onChange={e => setState({...state, login:e.target.value, errorText:""})}
                                />
                                <TextField
                                    required
                                    id="pwd"
                                    label="Пароль"
                                    className={classes.textField}
                                    type="password"
                                    margin="normal"
                                    onChange={e => setState({...state, pwd:e.target.value, errorText:""})}
                                />        
                                <div style={{padding:9, height:24}}>
                                    <p className={classes.error}>{state.errorText}</p>
                                </div>
                                <div className={classes.divButton}>
                                    <Button variant="contained" disabled={state.processing} className={classes.button} type="submit">Войти</Button>
                                    {state.processing && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </div>
                                {state.errorText.includes("1410") && 
                                    <div style={{height:24}}>
                                        {<FormControlLabel className={classes.textField} control={<Switch color="primary" checked={state.switchOn} onChange={toggleChecked} />} label="предварительно закрыв все сессии"/>}
                                    </div>
                                }
                            </CardContent>                        
                        </form>
                        </div>
                    </Card>
                </div>
            </div>
        </React.Fragment>)
}

export default LoginPage;